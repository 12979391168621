import React from "react"
import { graphql } from "gatsby"
import { Grid, Flex, Title } from "../../components/system"

import Layout from "../../components/layout"
import Product from "../../components/Product"

const Men = ({ data }) => {
  const products = data.allSanityProduct.nodes

  return (
    <Layout dark logo="primary">
      <Title pt={["40%", "12%"]} pb={5}>
        Men
      </Title>
      <Flex>
        <Grid
          gridGap={50}
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          mx={[2, 4, 4]}
          mb={5}
        >
          {products.map((product, key) => (
            <Product data={product} key={key} />
          ))}
        </Grid>
      </Flex>
    </Layout>
  )
}

export default Men

export const pageQuery = graphql`
  query men {
    allSanityProduct(
      filter: { tags: { elemMatch: { list: { eq: "Men" } } } }
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        id
        name
        price
        empty
        slug {
          current
        }
        brand {
          name
        }
        images {
          asset {
            gatsbyImageData(aspectRatio: 0.8)
          }
        }
      }
    }
  }
`
